/* Scrollbar */

::-webkit-scrollbar {
  display: none;
  height: 10px !important;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f12f;
  width: 1px !important;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  width: 1px !important;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* SPINNER */

@keyframes spinner {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.spinner div {
  position: absolute;
  width: 45px;
  height: 45px;
  border: 5px solid #85a2b6;
  border-top-color: transparent;
  border-radius: 50%;
}
.spinner div {
  animation: spinner 1s linear infinite;
  top: 50%;
  left: 50%;
}
.spinner-container {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  opacity: 0.7;
  z-index: 1;
}
.spinner {
  width: 100%;
  height: 100%;
  background: #f1f2f3;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.spinner div {
  box-sizing: content-box;
}

/* Button spinner */

.spinner-button {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
  display: inline-block;
  vertical-align: text-bottom;
  margin-bottom: 2px;
  margin-right: 3px;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-button 0.75s linear infinite;
  animation: spinner-button 0.75s linear infinite;
}

@keyframes spinner-button {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Checkmark */

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: rgb(34, 211, 238);
  fill: none;
  animation: stroke 0.2s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: block;
  stroke-width: 4;
  stroke: #fff; /* checkmark color */
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px rgb(34, 211, 238);
  animation: fill 0.2s ease-in-out 0.2s forwards, scale 0.3s ease-in-out 0.4s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.4s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px rgb(34, 211, 238);
  }
}

/* Image Fade In */

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in-image { animation: fadeIn ease-in-out 1s; }